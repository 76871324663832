@import "../../../App.scss";

.section-space {
  &.trending-space {
    padding: 40px 0px 100px;
    @media (max-width: 1199.98px) {
      padding: 40px 0px 80px;
    }
    @media (max-width: 767.98px) {
      padding: 30px 0px 60px;
    }
    @media (max-width: 576.98px) {
      padding: 20px 0px 60px;
    }
  }
}

.trending-product-section {
  position: relative;
  padding: 0 0 60px 0;
  overflow: hidden;
  overflow: hidden;
  .trending-swiper {
    overflow: visible;
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      top: inherit;
      left: calc(50% - 50px);
      bottom: -55px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      &::after {
        font-size: 18px;
        font-weight: 900;
        color: $primary;
        right: 1px;
        position: relative;
      }
    }
    .swiper-button-next {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      top: inherit;
      right: calc(50% - 50px);
      bottom: -55px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      &::after {
        color: $primary;
        font-size: 18px;
        font-weight: 900;
        right: -1px;
        position: relative;
      }
    }
  }
}

.view-product-box {
  position: relative;
  text-align: center;
  width: 100%;
  .primary-btn {
    margin-top: 30px;
  }
}
