@import "../../../App.scss";

.service-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 1199.98px) {
    margin-bottom: 30px;
  }
  @media (max-width: 991.98px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .service-icon {
  }
  .service-details {
    position: relative;
    margin-left: 25px;
    padding-left: 25px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-left: 0;
      padding-left: 0;
      text-align: center;
      margin-top: 15px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 40px;
      background-color: $border;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 991.98px) {
        display: none;
      }
    }
    .service-title {
      font-size: 19px;
      margin: 0 0 8px 0;
      font-weight: 500;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: $text-grey;
    }
  }
}

.col-xl-3 {
  &:nth-child(n + 3) {
    .service-box {
      @media (max-width: 1199.98px) {
        margin-bottom: 0px;
      }
      @media (max-width: 575.98px) {
        margin-bottom: 30px;
      }
    }
  }
  &:nth-child(n + 4) {
    .service-box {
      @media (max-width: 575.98px) {
        margin-bottom: 0px;
      }
    }
  }
}
