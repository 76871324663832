@import "../../../App.scss";

.compostable-banner {
  height: 1100px;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 1599.98px) {
    height: 900px;
  }
  @media (max-width: 1399.98px) {
    height: 780px;
  }
  @media (max-width: 991.98px) {
    height: 750px;
  }
  @media (max-width: 575.98px) {
    height: 650px;
  }
}
