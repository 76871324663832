/* Colors : Starts; */
$primary: #2d7100;
$secondary: #44b014;
$primary-dark: #276001;
$orange: #f49004;
$primary-light: #3b569a;
$primary-extralight: #f1f3f7;
$content-color: #333333;
$bg-color: #f0f0f0;

$white: #ffffff;
$black: #000000;
$text-grey: #777777;
$border: #dddddd;
$light-grey: #eeeeee;

$medium-gray: #888888;
$extra-light-gray: #f7f7f7;
$border-grey: #ced4da;
$dark-gray: #222222;
$middark-gray: #5d5d5d;

$input-text-disabled: #999;
$error-control-validation-border: #a94442;

$sts-green: #429842;
$sts-red: #851717;
$sts-orange: #ff5722;
$sts-yellow: #dfac11;
$sts-brown: #804a12;
$sts-blue: #2e93c5;
$sts-grey: #a9a9a9;
$blue: #32b3e1;
$grey-light: #787878;
$secondry-color: #e2f2fc;

$dashboard-blue: #0056ff;
$dashboard-green: #87c623;
$dashboard-purple: #711bf0;
$dashboard-orange: #ff7844;
$dashboard-pink: #ff3059;
$dashboard-red: #cd200b;
$dashboard-darkgreen: #00b4a3;

/* Colors : Ends */
$font-bold: 700;
$font-semibold: 600;
$font-regular: 500;
$font-light: 400;
$font-thin: 300;
$font-size: 14px;

$btn-blue: #3669ff;
$dark-blue: #0c3761;

$screen-xl: 1440;
