@import "../../../App.scss";

.product-slider {
  position: relative;
  width: 100%;
  .swiper {
    .swiper-slide {
      border: 1px solid $border;
      border-radius: 15px;
      overflow: hidden;
      text-align: center;
    }
  }
  .main-slider {
    .swiper-button-prev {
      width: 35px;
      height: 35px;
      background: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      &::after {
        font-size: 16px;
        font-weight: 900;
        color: $primary;
        right: 1px;
        position: relative;
      }
    }
    .swiper-button-next {
      width: 35px;
      height: 35px;
      background: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      &::after {
        color: $primary;
        font-size: 16px;
        font-weight: 900;
        right: -1px;
        position: relative;
      }
    }
    .main-slider-img {
      height: 600px;
      width: 100%;
      object-fit: contain;
      @media (max-width: 767.98px) {
        height: 500px;
      }
      @media (max-width: 575.98px) {
        height: 400px;
      }
    }
  }
  .nav-slider {
    margin-top: 30px;
    .nav-slider-img {
      height: 120px;
      object-fit: cover;
      @media (max-width: 767.98px) {
        height: 100px;
        object-fit: contain;
      }
    }
  }
}

.product-details-section {
  position: relative;
  width: 100%;
  padding-left: 40px;
  @media (max-width: 991.98px) {
    padding-left: 0;
    margin-top: 40px;
  }
  .product-data {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid $border;
    @media (max-width: 991.98px) {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    .product-title {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 20px;
      @media (max-width: 991.98px) {
        font-size: 28px;
      }
      @media (max-width: 767.98px) {
        font-size: 24px;
      }
    }
    .product-details {
      font-size: 18px;
      margin-bottom: 0px;
      color: $text-grey;
      font-weight: 400;
    }
  }
  .product-form {
    .product-form-title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px;
      letter-spacing: 0px;
      @media (max-width: 991.98px) {
        margin-bottom: 20px;
      }
    }
  }
}
