@import "../../../App.scss";

#MainBannerSection {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.satva-swiper {
  .swiper-button-prev {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    left: 30px;
    &::after {
      font-size: 16px;
      font-weight: 900;
      color: $primary;
      right: 1px;
      position: relative;
    }
  }
  .swiper-button-next {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    right: 30px;
    &::after {
      color: $primary;
      font-size: 16px;
      font-weight: 900;
      right: -1px;
      position: relative;
    }
  }
}
.main-banner {
  position: relative;
  height: calc(100vh - 140px);
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 1px solid $border;
  background-color: $white;
  &.banner1 {
    background-image: url("../../../../public/images/banner1.jpg");
  }
  @media (max-width: 991.98px) {
    height: 600px;
    background-position: bottom left;
  }
  @media (max-width: 767.98px) {
    background-size: 160%;
  }
  .product-banner-img {
    position: absolute;
    top: 50%;
    left: 31%;
    transform: translate(-50%, -50%);
    width: 640px;
    @media (max-width: 1399.98px) {
      width: 530px;
    }
    @media (max-width: 991.98px) {
      width: 370px;
      left: 26%;
    }
    @media (max-width: 767.98px) {
      width: 310px;
      left: 50%;
      top: 150px;
    }
    @media (max-width: 575.98px) {
      width: 270px;
      top: 140px;
    }
  }
}
.text-position {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: calc(100vh - 140px);
  text-align: right;
  width: 100%;
  @media (max-width: 991.98px) {
    height: 600px;
    width: 100%;
    right: -20px;
    position: relative;
  }
  @media (max-width: 767.98px) {
    right: 0;
    width: 100%;
    align-items: end;
    justify-content: center;
  }
}
.banner-text {
  position: relative;
  margin: 100px 0px;
  @media (max-width: 767.98px) {
    margin: 0px 0px 40px 0px;
    text-align: center;
  }
  .banner-title {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0 0 20px 0;
    @media (max-width: 767.98px) {
      margin: 0 0 20px 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100px;
      height: 5px;
      background-color: $primary;
      bottom: 0;
      right: 0;
      @media (max-width: 767.98px) {
        width: 70px;
        height: 3px;
        right: inherit;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .sub-title {
      font-size: 72px;
      font-family: "Dynalight";
      color: $orange;
      @media (max-width: 1399.98px) {
        font-size: 56px;
      }
      @media (max-width: 767.98px) {
        font-size: 36px;
        line-height: 40px;
        text-align: center;
      }
    }
    .tag-title {
      font-size: 124px;
      line-height: 112px;
      font-family: "Dynalight";
      color: $primary;
      margin: 0;
      @media (max-width: 1399.98px) {
        font-size: 100px;
        line-height: 82px;
      }
      @media (max-width: 767.98px) {
        font-size: 60px;
        line-height: 52px;
        text-align: center;
      }
    }
  }
  .banner-details {
    width: 70%;
    font-size: 18px;
    color: $black;
    text-align: right;
    margin-left: auto;
    font-weight: 400;
    margin-bottom: 50px;
    @media (max-width: 1399.98px) {
      width: 80%;
      margin-bottom: 30px;
    }
    @media (max-width: 767.98px) {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}
