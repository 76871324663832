@import "../../../App.scss";

.breadcrumb-section {
  position: relative;
  width: 100%;
  padding: 50px 20px;
  border-bottom: 3px solid $light-grey;
  @media (max-width: 767.98px) {
    padding: 30px 20px;
  }
  .leaf-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
}
.breadcrumb-box {
  .breadcrumb {
    margin: 0;
    .breadcrumb-item {
      font-size: 18px;
      @media (max-width: 575.98px) {
        font-size: 16px;
      }
      a {
        color: $primary;
      }
    }
  }
  .hideItem {
    display: none;
  }
  .disableItem {
    a {
      text-decoration: none;
      color: #6c757d !important;
      pointer-events: none;
    }
  }
}
