@import "../../../App.scss";

.benifit-banner {
  background-image: url("../../../../public/images/benifit-banner.jpg");
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 20px;
  overflow: hidden;
  @media (max-width: 1199.98px) {
    height: 550px;
  }
  @media (max-width: 575.98px) {
    height: 450px;
  }
  .benifit-section {
    @media (max-width: 1199.98px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 800px;
      @media (max-width: 767.98px) {
        width: 500px;
      }
      @media (max-width: 575.98px) {
        width: 430px;
      }
      @media (max-width: 430.98px) {
        width: 350px;
      }
    }
    .desktop-benifit {
      display: block;
      @media (max-width: 767.98px) {
        display: none;
      }
    }
    .mobile-benifit {
      display: none;
      @media (max-width: 767.98px) {
        display: block;
      }
    }
  }
}
