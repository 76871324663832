@import "../../../App.scss";

.title-box {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 2;
  .title-text {
    position: relative;
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 25px;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 600;
    @media (max-width: 1199.98px) {
      font-size: 32px;
    }
    @media (max-width: 767.98px) {
      font-size: 28px;
      margin-bottom: 40px;
    }
    @media (max-width: 575.98px) {
      font-size: 26px;
      padding-bottom: 26px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 120px;
      height: 2px;
      background: $primary;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background: $primary;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      @media (max-width: 767.98px) {
        width: 11px;
        height: 11px;
        bottom: -9px;
      }
      @media (max-width: 575.98px) {
        bottom: -9px;
      }
    }
    span {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: $primary;
        bottom: -33px;
        left: calc(50% - 20px);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        @media (max-width: 767.98px) {
          width: 11px;
          height: 11px;
        }
        @media (max-width: 575.98px) {
          bottom: -35px;
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: $primary;
        bottom: -33px;
        left: calc(50% + 20px);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        @media (max-width: 767.98px) {
          width: 11px;
          height: 11px;
        }
        @media (max-width: 575.98px) {
          bottom: -35px;
        }
      }
    }
  }
}

.about-title {
  text-align: left;
  @media (max-width: 1199.98px) {
    text-align: center;
  }
  &.title-box {
    .title-text {
      margin-bottom: 30px;
      &::before {
        left: 50px;
        width: 90px;
        @media (max-width: 1199.98px) {
          left: 50%;
        }
      }
      &::after {
        left: 25px;
        @media (max-width: 1199.98px) {
          left: 50%;
        }
      }
    }
    span {
      &::after {
        left: 6px;
        @media (max-width: 1199.98px) {
          left: calc(50% + 20px);
        }
      }
      &::before {
        left: 45px;
        @media (max-width: 1199.98px) {
          left: calc(50% - 20px);
        }
      }
    }
  }
}
