@import "../../../App.scss";

.team-member-card {
  border: 0;
  position: relative;
  @media (max-width: 1199.98px) {
    margin-bottom: 30px;
  }
  .card-img {
    height: 370px;
    object-fit: cover;
    border-radius: 20px;
    border-bottom: 5px solid $primary;
    transition: 0.3s all ease;
  }
  .card-body {
    text-align: center;
    padding-bottom: 0;
    .member-position {
      font-size: 16px;
      font-weight: 400;
      color: $black;
      margin: 0 0 5px 0;
    }
    .card-title {
      font-size: 20px;
      color: $black;
      margin: 0 0 0px 0;
    }
    .members-links {
      position: relative;
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          margin: 0 9px;
          .social-links {
            position: relative;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            z-index: 2;
            transition: 0.3s all ease;
            color: $black;
            &::before {
              content: "";
              position: absolute;
              background-color: $light-grey;
              width: 100%;
              height: 100%;
              border-radius: 12px;
              z-index: -1;
              transform: rotate(20deg);
              transition: 0.3s all ease;
            }
            &:hover {
              color: $white;
              &::before {
                transition: 0.3s all ease;
                transform: rotate(0deg);
                background-color: $primary;
              }
            }
          }
        }
      }
    }
  }
  // &:hover {
  //   .card-img {
  //     transition: 0.3s all ease;
  //     border-bottom: 10px solid $primary;
  //   }
  // }
}

.team-mem {
  &:nth-child(n + 3) {
    .team-member-card {
      @media (max-width: 1199.98px) {
        margin-bottom: 0px;
      }
      @media (max-width: 575.98px) {
        margin-bottom: 30px;
      }
    }
  }
}
