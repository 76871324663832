@import "../../../App.scss";

.custom-form-group {
  margin-bottom: 20px;
  &.submit-btns {
    display: flex;
    width: 100%;
    margin-top: 30px !important;
    .primary-btn {
      margin-left: auto;
      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
  }
  &:last-child {
    margin: 0;
  }
}
.contact-form {
  &.contactus-f {
    position: relative;
    z-index: 1;
  }
  .form-label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 300;
  }
  .form-control,
  .form-select {
    height: 50px;
    border-radius: 10px;
    padding: 10px 20px;
  }
  textarea {
    height: auto !important;
  }
}
