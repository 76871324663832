@import "../../../App.scss";

.product-card {
  position: relative;
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  .card-img {
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: 767.98px) {
      height: 250px;
    }
    &.plist-box {
      @media (max-width: 1199.98px) {
        height: 250px;
      }
      @media (max-width: 575.98px) {
        height: 170px;
      }
    }
  }
  .card-body {
    padding: 0;
    .card-title {
      font-size: 18px;
      text-align: center;
      margin: 15px 0px;
      padding: 0 10px;
      letter-spacing: 0rem;
      color: $dark-gray;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
