@import "../../../App.scss";

header {
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.5s all ease;
  &.menuscroll {
    transition: 0.5s all ease;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  }
}

.navbar {
  position: relative;
  background-color: $light-grey;
  width: 100%;
  @media (max-width: 767.98px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  .navbar-brand {
    img {
      width: 190px;
      object-fit: contain;
      @media (max-width: 991.98px) {
        width: 150px;
      }
    }
  }
  .navbar-toggler {
    box-shadow: none;
    border: 1px solid $text-grey;
    padding: 5px 10px;
    .navbar-toggler-icon {
      width: 1.3em;
    }
  }
  .navbar-collapse {
    justify-content: flex-end;
  }
  .navbar-nav {
    margin-right: -20px;
    @media (max-width: 991.98px) {
      margin: 10px 0px;
    }
    .nav-link {
      font-size: 18px;
      color: $black;
      font-weight: 400;
      padding: 10px 20px;
      transition: 0.3s all ease;
      position: relative;
      @media (max-width: 991.98px) {
        padding: 5px 0px;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      &:hover,
      &.active {
        color: $primary;
        transition: 0.3s all ease;
        &::before {
          content: "";
          position: absolute;
          width: calc(100% - 30px);
          height: 3px;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 0%);
          background-color: $primary;
          @media (max-width: 991.98px) {
            width: 100%;
            height: 2px;
          }
        }
      }
      &.HideNavLink {
        display: none;
      }
    }
  }
}
