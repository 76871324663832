@import "./ui/main.scss";

@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}

// Button Section
.primary-btn {
  position: relative;
  display: inline-block;
  border: 0;
  border-radius: 15px;
  font-size: 18px;
  padding: 15px 50px 15px 50px;
  color: $white;
  background-color: $primary;
  transition: 0.3s all ease;
  @media (max-width: 575.98px) {
    font-size: 16px;
    padding: 15px 30px 15px 30px;
  }
  i {
    transition: 0.3s all ease;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  &:hover {
    transition: 0.3s all ease;
    padding: 15px 65px 15px 50px;
    background-color: $primary-dark;
    @media (max-width: 575.98px) {
      padding: 15px 60px 15px 30px;
    }
    i {
      transition: 0.3s all ease;
      opacity: 1;
      right: 30px;
    }
  }
}

// Card Button
.card-btn {
  background-color: $light-grey;
  border: 0;
  width: 100%;
  color: $dark-gray;
  text-transform: capitalize;
  padding: 10px 20px;
  border-radius: 10px;
  transition: 0.3s all ease;
  &:hover,
  &:active {
    background-color: $primary !important;
    color: $white;
    transition: 0.3s all ease;
  }
  &:focus-visible,
  &:active {
    background-color: $primary;
    box-shadow: none;
    color: $white;
    transition: 0.3s all ease;
  }
}

// Section Sapce
.medium-space {
  padding: 60px 0px;
}
.section-space {
  padding: 100px 0px;
  @media (max-width: 1199.98px) {
    padding: 80px 0px;
  }
  @media (max-width: 767.98px) {
    padding: 60px 0px;
  }
  @media (max-width: 576.98px) {
    padding: 50px 0px;
  }
}

// Title Section
