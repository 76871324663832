@import "../../../App.scss";

.bag-banner {
  height: 800px;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    @media (max-width: 1199.98px) {
      object-position: right;
    }
    @media (max-width: 575.98px) {
      object-position: 87%;
    }
  }
  @media (max-width: 1399.98px) {
    height: 570px;
  }
  @media (max-width: 575.98px) {
    height: 500px;
  }
}
