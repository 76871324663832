@import "../../../App.scss";

.category-product-section {
  position: relative;
  padding: 0 0 60px 0;
  overflow: hidden;
  overflow: hidden;
  .category-swiper {
    overflow: visible;
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      top: inherit;
      left: calc(50% - 50px);
      bottom: -55px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      &::after {
        font-size: 18px;
        font-weight: 900;
        color: $primary;
        right: 1px;
        position: relative;
      }
    }
    .swiper-button-next {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      top: inherit;
      right: calc(50% - 50px);
      bottom: -55px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      &::after {
        color: $primary;
        font-size: 18px;
        font-weight: 900;
        right: -1px;
        position: relative;
      }
    }
  }
}

.tab-product-nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  @media (max-width: 575.98px) {
    flex-wrap: wrap;
  }
  .nav-item {
    @media (max-width: 575.98px) {
      width: 100%;
    }
    .nav-link {
      background: 0 0;
      border: 0;
      padding: 10px 10px;
      width: 235px;
      background-color: $white;
      border: 1px solid $border;
      border-radius: 8px;
      margin: 0 10px;
      color: $black;
      text-transform: capitalize;
      font-size: 16px;
      text-align: center;
      &.active {
        background-color: $primary;
        color: $white;
        border-color: $primary;
      }
      @media (max-width: 1399.98px) {
        width: auto;
        padding: 10px 15px;
      }
      @media (max-width: 991.98px) {
        margin: 0 5px 10px;
      }
      @media (max-width: 575.98px) {
      }
    }
  }
}

.view-product-box {
  position: relative;
  text-align: center;
  width: 100%;
  .primary-btn {
    margin-top: 30px;
  }
}
