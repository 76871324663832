@import "../../../App.scss";

footer {
  border-top: 3px solid $bg-color;
}

.footer-box {
  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-brand-details {
    font-weight: 400;
    letter-spacing: 0.01rem;
    font-size: 16px;
    color: $text-grey;
  }
  &.footer-center-box {
    @media (max-width: 991.98px) {
      margin: 40px 0px;
    }
  }
}

.members-links {
  position: relative;
  margin-top: 30px;
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    li {
      margin: 0 9px;
      .social-links {
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        z-index: 2;
        transition: 0.3s all ease;
        color: $black;
        &::before {
          content: "";
          position: absolute;
          background-color: $light-grey;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          z-index: -1;
          transform: rotate(20deg);
          transition: 0.3s all ease;
        }
        &:hover {
          color: $white;
          &::before {
            transition: 0.3s all ease;
            transform: rotate(0deg);
            background-color: $primary;
          }
        }
      }
    }
  }
}

.footer-title {
  position: relative;
  width: 100%;
  h4 {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 40px;
    margin-top: 10px;
    padding-bottom: 25px;
    @media (max-width: 991.98px) {
      margin-top: 0px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 60px;
      height: 3px;
      background-color: $primary;
      bottom: 0;
      left: 0;
    }
  }
}

.footer-contact {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    i {
      margin-top: 2px;
      font-size: 18px;
      color: $primary-dark;
      margin-right: 15px;
    }
  }
}

.footer-map {
  position: relative;
  iframe {
    position: relative;
    height: 170px;
    width: 100%;
  }
}

.copyright-box {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 15px 10px;
  background-color: $bg-color;
  h6 {
    margin: 0;
    font-size: 14px;
  }
}
