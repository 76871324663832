@import "../../../App.scss";

.listing-product-row {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767.98px) {
    margin: 0 -5px;
  }
  .list-product {
    width: 20%;
    padding: 0 12px;
    margin-bottom: 40px;
    @media (max-width: 1199.98px) {
      width: 25%;
    }
    @media (max-width: 991.98px) {
      width: 33.33%;
    }
    @media (max-width: 767.98px) {
      width: 50%;
      padding: 0 5px;
      margin-bottom: 30px;
    }
  }
}
.search-panel {
  position: relative;
  margin-bottom: 50px;
  @media (max-width: 767.98px) {
    margin-bottom: 30px;
  }
  .custom-search {
    position: relative;
    height: 50px;
    border-radius: 10px;
    padding: 10px 20px 10px 50px;
    &:focus {
      border-color: $primary;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(45, 113, 0, 0.25);
    }
  }
  .search-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0%, -50%);
    z-index: 1;
    font-size: 18px;
    color: $primary;
  }
}
