@import "../../../App.scss";

.contactus-section {
  background-color: $bg-color;
  position: relative;
  overflow: hidden;
  .contactus-img {
    position: absolute;
    bottom: -50px;
    right: 0;
    z-index: 0;
    @media (max-width: 1399.98px) {
      width: 90%;
    }
    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 767.98px) {
      width: 100%;
      display: none;
    }
  }
}
