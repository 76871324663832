@import "../../../App.scss";

.aboutus-swiper {
  border-radius: 20px;
  .aboutus-img {
    height: 505px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    @media (max-width: 991.98px) {
      height: 450px;
    }
    @media (max-width: 767.98px) {
      height: 420px;
    }
    @media (max-width: 575.98px) {
      height: 380px;
    }
  }
  .swiper-button-prev {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    &::after {
      font-size: 16px;
      font-weight: 900;
      color: $primary;
      right: 1px;
      position: relative;
    }
  }
  .swiper-button-next {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    &::after {
      color: $primary;
      font-size: 16px;
      font-weight: 900;
      right: -1px;
      position: relative;
    }
  }
}

.aboutus-text {
  position: relative;
  height: 100%;
  padding-left: 46px;
  @media (max-width: 1199.98px) {
    padding-left: 0px;
    margin-top: 40px;
  }
  p {
    margin: 0;
    font-size: 18px;
    text-align: justify;
  }
}
