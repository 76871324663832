@import "../../../App.scss";

.notfound-main-section {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../public/images/banner1.jpg");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1199.98px) {
    background-size: 180%;
  }
  .notfound-sec {
    position: relative;
    text-align: center;
    .notfound-title {
      font-size: 66px;
      line-height: 66px;
      font-weight: 900;
      margin-bottom: 0;
      color: $primary;
      @media (max-width: 1199.98px) {
        font-size: 56px;
        line-height: 48px;
      }
    }
    .notfound-img {
      width: 400px;
      margin: 30px 0;
      @media (max-width: 1199.98px) {
        width: 350px;
        margin: 20px 0;
      }
    }
  }
  .notfound-text-box {
    position: relative;
    padding: 0 20px;
    h3 {
      font-size: 34px;
      font-weight: 900;
      margin-bottom: 20px;
      @media (max-width: 991.98px) {
        font-size: 30px;
      }
    }
    p {
      width: 60%;
      margin: 0 auto 20px;
      font-size: 18px;
      color: #777777;
      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
  }
}
