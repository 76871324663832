@import "../../../App.scss";

.topbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  width: 100%;
  @media (max-width: 575.98px) {
    justify-content: center;
    padding: 10px 0px;
  }
  .welcome-text {
    font-size: 16px;
    font-weight: 400;
    color: $text-grey;
    margin: 0;
    @media (max-width: 575.98px) {
      font-size: 14px;
    }
  }
}
.call-info {
  display: flex;
  align-items: center;
  @media (max-width: 575.98px) {
    display: none;
  }
  i {
    font-size: 16px;
    color: $primary;
    margin: 0 10px 0 0;
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    span {
      color: $black;
      font-weight: 500;
    }
  }
}
